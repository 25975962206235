import axios from "axios";
import { destroyToken } from "./Token";
import { axiosBaseUrl } from "./axiosBaseUrl";

const axiosOrdersRequest = axios.create({
  baseURL: "https://edonish.market/ordersapi/",
  // withCredentials: true
});

axiosOrdersRequest.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
  return config;
});

axiosOrdersRequest.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("token");
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosOrdersRequest.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const {
      config,
      response: { status },
    } = error;
    const originalRequest = config;

    if (status === 401) {
      const refreshToken = localStorage.getItem("refresh_token");
      if (!refreshToken) {
        destroyToken();
        return Promise.reject(error);
      }
      // make a request to refresh token and update the headers
      try {
        const { data } = await axiosBaseUrl.post("token_refresh", {
          refresh_token: refreshToken,
        });
        localStorage.setItem("token", data.accessToken);
        originalRequest.headers.Authorization = `Bearer ${data.accessToken}`;
        return axios(originalRequest);
      } catch (err) {
        destroyToken();
        return Promise.reject(err);
      }
    }
    return Promise.reject(error);
  }
);

export { axiosOrdersRequest };
