import { Suspense, lazy, useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import './App.css';
import Loading from './components/loading/Loading';
// import 'antd/dist/antd';
import "swiper/css";
import "swiper/css/pagination";
import { getToken } from './utils/Token';
import { setRealTimeObj } from './apiRedux/reducers/globalState';
import { useDispatch } from 'react-redux';

const Layout = lazy(()=> import ('./pages/layout/Layout'));
const Orders = lazy(()=> import ('./pages/orders/Orders'));
const Archives = lazy(()=> import ('./pages/archives/Archives'));
const Login = lazy(()=> import ('./pages/login/Login'));
const PrintDescription = lazy(()=> import ('./pages/printDescription/PrintDescription'));

function App() {
  const {pathname} = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(()=>{
    let token = getToken()
    if(pathname.toLowerCase().includes('/layout') && !token){
      navigate('/')
    }
    if(pathname == '/' && token){
      navigate('/layout')
    }
  },[pathname])
  return (
    <div className="App">
      <Routes>
        <Route path='/' element={<Suspense fallback={<Loading/>}><Login/></Suspense>}/>
        <Route path='layout' element={<Suspense fallback={<Loading/>}><Layout/></Suspense>}>
          <Route index element={<Suspense fallback={<Loading/>}><Orders/></Suspense>} />
          <Route path='archives' element={<Suspense fallback={<Loading/>}><Archives/></Suspense>} />
          <Route path='print' element={<Suspense fallback={<Loading/>}><PrintDescription/></Suspense>}/>
        </Route>
      </Routes>
    </div>
  );
}

export default App;