import { createSlice } from "@reduxjs/toolkit";

const locallhostCuorerState = createSlice({
  name: "locallhostCuorerState",
  initialState: {
    modalState: {},
  },
  reducers: {
    setModalState: (state, actions) => {
      state.modalState = actions.payload
    }
  },
});

export const {
  setModalState,
} = locallhostCuorerState.actions;

export default locallhostCuorerState.reducer;
