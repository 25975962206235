import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import Loading from "./components/loading/Loading";
import store, { persistor } from './apiRedux/store/store'
import { PersistGate } from "redux-persist/integration/react";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <PersistGate loading={<loading />} persistor={persistor}>
          <Suspense fallback={<Loading />}>
            <App />
          </Suspense>
        </PersistGate>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);
