import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { axiosBaseRequest, axiosBaseUrl } from "../../utils/axiosBaseUrl";
import { destroyToken, getToken, saveToken } from "../../utils/Token";
import { message } from "antd";
import { axiosOrdersRequest } from "../../utils/axiosOrdersURL";

export const loginUser = createAsyncThunk(
  "login/loginUser",
  async ({ body, navigate }, { rejectWithValue }) => {
    try {
      let uri = `login/`;
      const { data } = await axiosBaseUrl.post(uri, body, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      saveToken(data.access_token, data.refresh_token);
      navigate("/layout");
      return data;
    } catch (error) {
      message.error(error.response.data.detail);
      return rejectWithValue(error.message);
    }
  }
);

export const logout = createAsyncThunk(
  "users/logout",
  async (_, { rejectWithValue }) => {
    try {
      let uri = `logout`;

      const { data } = await axiosBaseUrl.post(uri);
      destroyToken();
      return data;
    } catch (error) {
      message.error(error.response.data.detail);
      return rejectWithValue(error.message);
    }
  }
);

export const getOrders = createAsyncThunk(
  "users/getOrders",
  async ({ searchText = null, offset = 0, limit = 15, type = null }, { rejectWithValue }) => {
    try {
      const { data } = await axiosOrdersRequest.get(
        `order/courier/restricted/all/${searchText ? `?q=${searchText}` : `${type ? `?order_status=${type}&` : '?'}limit=${limit}&offset=${offset}`}`
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getArchives = createAsyncThunk(
  "users/getArchives",
  async ({ searchText = null, offset = 0, limit = 15, type = null }, { rejectWithValue }) => {
    try {
      const { data } = await axiosOrdersRequest.get(
        `order/archive/all/${searchText ? `?q=${searchText}` : `${type ? `?order_status=${type}&` : '?'}limit=${limit}&offset=${offset}`}`
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const setStatusOrder = createAsyncThunk(
  "users/setStatusOrder",
  async (body, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await axiosOrdersRequest.patch(
        `order/status/?order_uuid=${body.uuid}&order_status=${body.status}`
      );
      dispatch(setRefresh(Math.random()));
      return data;
    } catch (error) {
      return rejectWithValue(error.message);

    }
  }
);

export const getUserData = createAsyncThunk(
  "users/getUserData",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosBaseRequest.get(`users/me`);
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const setUserData = createAsyncThunk(
  "users/setUserData",
  async (body, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await axiosBaseRequest.patch(`users/`, body);
      dispatch(refreshRefreshToken());
      return data;
    } catch (error) {
      message.error(error.response.data.detail);
      return rejectWithValue(error.message);
    }
  }
);

export const refreshRefreshToken = createAsyncThunk(
  "users/refreshRefreshToken",
  async (body, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await axiosBaseRequest.post(`token_refresh/`, {
        refresh_token: localStorage.getItem("refresh_token"),
      });
      saveToken(data.access_token, data.refresh_token);
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const setUserPassword = createAsyncThunk(
  "users/setUserPassword",
  async (body, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await axiosBaseRequest.patch(
        `users/reset-password/`,
        body
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
);

const globalState = createSlice({
  name: "states",
  initialState: {
    loading: false,
    loginStatus: "",
    ordersData: {orders: []},
    filterState: 1,
    refresh: 0,
    modalStateRefresh: {},
    userData: {},
    modalPassword: false,
    modalFilter: false,
    modalProfile: false,
    offset: 0,
    searchText: '',
    current: 1,
    realTimeObj: {},
  },
  reducers: {
    setFilterState: (state, action) => {
      state.filterState = action.payload;
    },
    setRefresh: (state, action) => {
      state.refresh = action.payload;
    },
    setModalStateRefresh: (state, action) => {
      state.modalStateRefresh = action.payload;
    },
    setModalPassword: (state, action) => {
      state.modalPassword = action.payload;
    },
    setModalFilter: (state, action) => {
      state.modalFilter = action.payload;
    },
    setModalProfile: (state, action) => {
      state.modalProfile = action.payload;
    },
    setSearchText: (state, action) => {
      state.searchText = action.payload
    },
    setCurrent: (state, action) => {
      state.current = action.payload
    },
    setRealTimeObj: (state, action) => {
      let loopState = false;
      if(!state.ordersData.orders){
        state.ordersData.orders = [action.payload]
      }else{
        for (let i = 0; i <= state.ordersData.orders.length; i++) {
          if (state?.ordersData?.orders[i]?.order_id === action.payload.order_id) {
            state.ordersData.orders.splice(i, 1, action.payload);
            loopState = true;
            break;
          }
        }
        !loopState && state.ordersData.orders.unshift(action.payload);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginUser.pending, (state, action) => {
      state.loginStatus = "pending";
      state.loading = true;
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.loginStatus = "success";
      state.loading = false;
    });
    builder.addCase(loginUser.rejected, (state, action) => {
      state.loginStatus = "rejected";
      state.loading = false;
    });
    builder.addCase(getOrders.pending, (state, action) => {
      state.loginStatus = "pending";
      state.loading = true;
    });
    builder.addCase(getOrders.fulfilled, (state, action) => {
      state.loginStatus = "success";
      state.ordersData = action.payload;
      state.loading = false;
    });
    builder.addCase(getOrders.rejected, (state, action) => {
      state.loginStatus = "rejected";
      state.loading = false;
    });
    builder.addCase(getArchives.pending, (state, action) => {
      state.loginStatus = "pending";
      state.loading = true;
    });
    builder.addCase(getArchives.fulfilled, (state, action) => {
      state.loginStatus = "success";
      state.ordersData = action.payload;
      state.loading = false;
    });
    builder.addCase(getArchives.rejected, (state, action) => {
      state.loginStatus = "rejected";
      state.loading = false;
    });
    builder.addCase(setStatusOrder.pending, (state, action) => {
      state.loginStatus = "pending";
      state.loading = true;
    });
    builder.addCase(setStatusOrder.fulfilled, (state, action) => {
      state.loginStatus = "success";
      state.loading = false;
    });
    builder.addCase(setStatusOrder.rejected, (state, action) => {
      state.loginStatus = "rejected";
      state.loading = false;
    });
    builder.addCase(getUserData.pending, (state, action) => {
      state.loginStatus = "pending";
      state.loading = true;
    });
    builder.addCase(getUserData.fulfilled, (state, action) => {
      state.loginStatus = "success";
      state.userData = action.payload;
      state.loading = false;
    });
    builder.addCase(getUserData.rejected, (state, action) => {
      state.loginStatus = "rejected";
      state.loading = false;
    });
    builder.addCase(setUserData.pending, (state, action) => {
      state.loginStatus = "pending";
      state.loading = true;
    });
    builder.addCase(setUserData.fulfilled, (state, action) => {
      state.loginStatus = "success";
      state.loading = false;
    });
    builder.addCase(setUserData.rejected, (state, action) => {
      state.loginStatus = "rejected";
      state.loading = false;
    });
    builder.addCase(refreshRefreshToken.pending, (state, action) => {
      state.loginStatus = "pending";
      state.loading = true;
    });
    builder.addCase(refreshRefreshToken.fulfilled, (state, action) => {
      state.loginStatus = "success";
      state.loading = false;
    });
    builder.addCase(refreshRefreshToken.rejected, (state, action) => {
      state.loginStatus = "rejected";
      state.loading = false;
    });
    builder.addCase(setUserPassword.pending, (state, action) => {
      state.loginStatus = "pending";
      state.loading = true;
    });
    builder.addCase(setUserPassword.fulfilled, (state, action) => {
      state.loginStatus = "success";
      state.modalPassword = false
      state.loading = false;
    });
    builder.addCase(setUserPassword.rejected, (state, action) => {
      state.loginStatus = "rejected";
      state.loading = false;
    });
  },
});

export const { 
  setFilterState, 
  setRefresh, 
  setModalStateRefresh,
  setModalPassword,
  setModalFilter,
  setModalProfile,
  setSearchText,
  setCurrent,
  setRealTimeObj,
} = globalState.actions;

export default globalState.reducer;
