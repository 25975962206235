import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const globalRequest = createApi({
  reducerPath: "globalRequest",
  tagTypes: ["CARDS"],
  baseQuery: fetchBaseQuery({ baseUrl: "https://edonish.market/api/v1/" }),
  endpoints: (build) => ({
    getCardsLimit: build.query({
      query: ({ limit, title }) =>
        `/items/${title != "" ? `?q=${title}` : `?offset=0&limit=${limit}`}`,
      providesTags: ["CARDS"],
    }),
    // postOrder: build.mutation({
    //     query:(body) => ({
    //         method:"POST",
    //         url:'/payment',
    //         body,
    //     })
    // })
  }),
});
export const {
  useGetCardsLimitQuery
} = globalRequest;

export default globalRequest;
